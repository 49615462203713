html {
  background-color: #F5F5F5;
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('montserrat_regular'), url(../public/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratBold';
  src: local('montserrat_bold'), url(../public/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
}

.App {
  text-align: center;
  background-color: #F5F5F5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content-width {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.email {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin: 0;
  color: #000000;
}

.address {
  display: inline-block;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin: 0;
  color: #000000;
}

.url {
  display: flex;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin: 0;
}

.cardProfile {
  width: 100%;
  margin-top: -45%;
  background-color: #FFFFFF;
  min-height: 200px;
  /*position: absolute;
  height: 217px;
  left: 32%;
  top: 10%;

  */
  box-shadow: 0px 4px 8px rgba(116, 112, 103, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

.cardText {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.btnDivIcon {
  display: inline-block;
  margin-right: 5px;
}

.textHeader {
  margin-left: 5%;
  width: 60%;
  display: flex;
  text-align: left;
  /*  justify-content: center;*/
}

.smartBusineessText {
  margin-left: 1%;
  display: inline-block;
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-align: center;

  color: #FDFDFD;

}



.divShareandSave {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.btnSaveContact {
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  width: 49%;
  text-align: center;
/*  background: rgba(0, 103, 172, 0.1);*/
  border: 1px solid #0067AC;
  color: #747067;
  padding: 3% 0%;
}

.hideText{
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #1B85EF;
}

.btnSharePage {
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  width: 49%;
  margin-left: 2%;
/*  background: #144B71;*/
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
/*  background: rgba(0, 103, 172, 0.1);*/
  border: 1px solid #0067AC;
  color: #747067;
  padding: 3% 0%;
}


.jobText {
  text-align: left;
  margin: 0;
  margin-left: 5%;
  margin-top: 5%;
  color: #000000;
}

.contactInfoText {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 1%;
}

.textLocation {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  margin: 0;
  color: #000000;
  text-align: left;
  margin-top: 3%;
  margin-left: 20%;

}

.btnGoToWebSite {
  font-family: 'MontserratBold';
  width: 100%;
  background: rgba(0, 103, 172, 0.1);
  border: 1px solid #0067AC;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 3% 0%;
  margin-bottom: 5%;

}
.textActionsWhite{
  color: #FFFFFF;

}
.textActions{
  color: #0067AC;

}

.btnPlayFiles {
  font-family: 'MontserratRegular';
  width: 100%;
  background: #0067AC;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 3% 0%;
  margin-bottom: 5%;
  border: 1px solid #0067AC;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #747067;
}

.btnDownloadFiles {
  background: rgba(0, 103, 172, 0.1);
  border: 1px solid #0067AC;
  border-radius: 8px;
  text-decoration: none;
  color: #747067;
  margin-left: 31%;
  margin-right: 31%;
  margin-top: 2%;
  padding: 1%;
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.img-wrap {
  width: 40%;
  height: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

/*
.img-wrap img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  background-size: cover;
  object-fit: cover;
}*/


.imageProfile {
  /*width: 180.02px;
  height: 217px;
  margin: 0;*/
  height: auto;
  border: 0px solid #D36B2A;
  border-radius: 20px;
  background-size: cover;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  background-position: center center;
}

.jobTextDetail {
  margin: 0% 5% 8% 5%;

}

.btngetCard {
  margin-left: 20%;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  text-align: center;
  border-width: 1px;
  border-color: #FFFFFF;
  border-radius: 5px;
  border-style: solid;
  padding-left: 5px;
  padding-right: 5px;
  /*  padding: 5px;*/
  text-decoration: none;
  background-color: #BFBFBF;
  color: white;
}

.App-header {
  padding-bottom: 2%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(32,32,32,1) 35%, rgba(71,71,71,1) 58%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 200px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.profileText {
  text-align: left;
  margin: 2% 8%;
/*  word-break: break-all;*/
}

.pincipalText{
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}

.btnActions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.phone {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin: 0;
  text-align: center;
  color: #000000;
}

.divInfo {
  text-align: center;
  display: flex;
  justify-content: center;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  padding-bottom: 20%;
  padding-top: 25%;
}

.filesText {
  text-align: left;
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.fileDiv {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-bottom: 1%;
}

.btnDiv {
  margin-top: 5%;
  margin-bottom: 1%;
}

.iconPrimary {
  margin-top: 20%;
  object-fit: contain;
  width: 45%;
}


.audioIcon {
  /*  margin-left: 25%,*/
  margin-top: 12%;
  object-fit: contain
}

.imageFile {
  border-radius: 8px;
  max-width: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  width: 100%;
}


.text {
  margin: 0;
  margin-top: 5%;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  overflow-wrap: break-word;
  color: #000000;
}

.text-filebox {
  margin-left: 5%;
  margin-right: 5%;
}

.boxFile {
  color: #727272;
  border: 0px solid;
  text-align: center;
  margin-top: 2%;
  padding: 0%;
  width: 49%;
  padding-bottom: 49%;
  height: 0;
  max-width: 50%;
  max-height: 50%;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.sizeDivFile {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #144B71;
}

.sizeDivFile after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.img-container {
  position: relative;
  width: 100%;
  /* The size you want */
}

.img-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.img-container img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
  background-color: white;
  border-radius: 10px;
}


.image-contain {
  object-fit: contain;
  object-position: center;
}

.image-cover {
  position: relative;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.H5 {
  font-family: 'MontserratBold';
  font-style: normal;
  font-size: 28px
}

.subtitle {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5%;
  color: #000000;
}

.modalShare {
  position: absolute;
  inset: 80% 10% auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  max-width: 400px;
  transform: translate(-50%, -50%);
}


.modalShareContent {
  margin-top: 1%;
  margin-left: 10%;
}

.divShareText {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.divShareTextCopy {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 5%;
  cursor: pointer;
}

.copyButton {
  background-color: transparent;
  border: medium none;
  padding: 2%;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

/*
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2),
screen and (max-width: 640px) {

  .btngetCard {
    margin-left: 5%;
  }

  .cardProfile {
    width: 345px;
    width: 345px;
    height: 217px;
    left: 5%;
    top: 8%;
  }

  .btnDiv {
    margin-top: 41%;
    margin-bottom: 6%;
  }

  .cardText {
    margin-top: 10%;
  }

  .App-header {
    padding-bottom: 15%;
  }

  .btnGoToWebSite {
    margin-left: 5%;
    margin-right: 5%;
    padding: 3%;
    margin-top: 2%;
    margin-bottom: 8%;
    font-family: 'MontserratBold';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;

  }

  .btnDownloadFiles {
    margin-left: 5%;
    margin-right: 5%;
    padding: 3%;
    margin-top: 8%;

  }

  .filesText {
    margin-left: 5%;
  }

  .btnSharePage {
    margin-left: 2%;
    padding-left: 8%;
    padding-right: 8%;
  }

  .btnSaveContact {
    padding-left: 8%;
    padding-right: 8%;
  }

  .jobText {
    margin-left: 12%;
  }

  .jobTextDetail {
    margin-left: 12%;
  }

  .textLocation {
    margin-left: 12%;
  }

  .nameProfile {
    margin-top: 0;
    margin-left: 0;
    font-size: 20px;
  }

  .imageProfile {
    width: 180.02px;
    height: 217px;
    margin: 0;
  }

  .imageAirPlane {
    margin: 0;
    margin-left: 2%;
    display: inline-block;
  }

  .fileDiv {
    margin-left: 5%;
    margin-right: auto;
  }

  .boxFile {
    padding: 1%;
  }

  .btnDivIcon {
    margin-right: 2%;
  }


}
*/