.preview-carroussel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.preview-header {
    height: 10%;
    display: flex;
    justify-content: center;
}

.preview-body {
    display: flex;
    flex-direction: row;
    height: 90%;
}

.preview-nav {
    width: 40%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.nav-left {
    justify-content: end;
}

.nav-right {
    justify-content: start;

}

.preview-close {
    width: 20%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding: 3%;*/

}

.preview-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.download-button {
    background-color: white;
    padding: 10px;
    font-family: 'MontserratBold';
    font-style: normal;
    font-size: 16px;
    border-radius: 10px;
    width: 250px;
    border: 1px solid #0067AC;;
    color: #0067AC;
    cursor: pointer;
    margin-top: 7%;
}

.preview-icon {
    height: 100%;
    width: 100%;
    max-width: 70%;
    max-height: 70%;
}

.file-name {
    font-family: 'MontserratRegular';
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10%;
}

.nav-image {
    height: 100%;
}

.nav-close-image {
    height: 75%;
    /** 
    Change SVG color: https://codepen.io/sosuke/pen/Pjoqqp
    */
}

.download-icon {
    height: 20px;
    transform: rotate(180deg);
    margin-right: 10px;
}